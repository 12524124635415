<template>
  <b-container>
    <b-tabs v-model="activeTab" pills>
      <!-- Education History Section -->
      <b-tab title="Education History">
        <b-card class="mb-3 history_card" title="">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveEducationHistory"
              >Save</b-button
            >
          </div>
          <b-card-body>
            <div v-for="(education, index) in activeEducationHistory">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <v-select
                        :options="educationOptions"
                        v-model="education.stq_name"
                        :reduce="(item) => item.value"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Course">
                      <b-form-input
                        v-model="education.stq_course"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="College">
                      <b-form-input
                        v-model="education.stq_college"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="College Address">
                      <b-form-input
                        v-model="education.stq_college_address"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Contact">
                      <b-form-input
                        v-model="education.stq_contact"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Awarding Board">
                      <b-form-input
                        v-model="education.stq_awarding_board"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country Institution">
                      <b-form-input
                        v-model="education.stq_country_instituition"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date From">
                      <b-form-input
                        type="date"
                        v-model="education.stq_date_from"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date To">
                      <b-form-input
                        type="date"
                        v-model="education.stq_date_to"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Grades">
                      <b-form-input
                        v-model="education.stq_grades"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                  <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteEducationHistory(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addEducationHistory"
              >Add Another Education</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <!-- Student Gaps Section -->
      <b-tab title="Gaps">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button type="button" variant="success"" @click="saveStudentGaps"
              >Save</b-button
            >
          </div>
          <b-card-body>
            <div v-for="(gap, index) in activeStudentGaps">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Period From">
                      <b-form-input
                        type="date"
                        v-model="gap.stg_period_from"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Period To">
                      <b-form-input
                        type="date"
                        v-model="gap.stg_period_to"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reason">
                      <b-form-input v-model="gap.stg_reason"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Backlogs">
                      <b-form-input v-model="gap.stg_backlogs"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentGaps(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentGap"
              >Add Another Gap</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <!-- Student Certifications Section -->
      <b-tab title="Certifications">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveStudentCertifications"
              >Save</b-button
            >
          </div>

          <b-card-body>
            <div v-for="(certification, index) in activeStudentCertifications">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <v-select
                        v-model="certification.stc_name"
                        :options="[
                          { value: 'ielts', label: 'IELTS' },
                          { value: 'others', label: 'OTHERS' },
                        ]"
                        
                        :reduce="(item) => item.value"
                        placeholder="Select Certification"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Overall">
                      <b-form-input
                        v-model="certification.stc_overall"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Speaking">
                      <b-form-input
                        v-model="certification.stc_speaking"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reading">
                      <b-form-input
                        v-model="certification.stc_reading"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Listening">
                      <b-form-input
                        v-model="certification.stc_listening"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Writing">
                      <b-form-input
                        v-model="certification.stc_writing"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date">
                      <b-form-input
                        type="date"
                        v-model="certification.stc_date"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentCertifications(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentCertification"
              >Add Another Certification</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Work History">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveStudentWorkHistory"
              >Save</b-button
            >
          </div>
          <b-card-body>
            <div v-for="(work, index) in activeStudentWorkHistory">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <v-select
                        :options="workNameOptions"
                        v-model="work.swh_name"
                        :reduce="(item) => item.value"
                        
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date From">
                      <b-form-input
                        type="date"
                        v-model="work.swh_date_from"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date To">
                      <b-form-input
                        type="date"
                        v-model="work.swh_date_to"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Organisation">
                      <b-form-input
                        v-model="work.swh_organisation"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Organisation Address">
                      <b-form-input
                        v-model="work.swh_org_address"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Position">
                      <b-form-input v-model="work.swh_position"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employer Name">
                      <b-form-input
                        v-model="work.swh_employer_name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employer Family Name">
                      <b-form-input
                        v-model="work.swh_employer_family_name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employer Work Contact">
                      <b-form-input
                        v-model="work.swh_employer_work_contact"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employer Contact">
                      <b-form-input
                        v-model="work.swh_employer_contact"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employer Email">
                      <b-form-input
                        v-model="work.swh_employer_email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentWorkHistory(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentWorkHistory"
              >Add Another Work History</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <!-- Student Travel History Section -->
      <b-tab title="Travel History">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveStudentTravelHistory"
              >Save</b-button
            >
          </div>
          <b-card-body>
            <div v-for="(travel, index) in activeStudentTravelHistory">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country">
                      <b-form-input v-model="travel.sth_country"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="From">
                      <b-form-input
                        type="date"
                        v-model="travel.sth_from"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="To">
                      <b-form-input
                        type="date"
                        v-model="travel.sth_to"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Purpose">
                      <b-form-input v-model="travel.sth_purpose"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentTravelHistory(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentTravelHistory"
              >Add Another Travel History</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <!-- Student Visa Rejections Section -->
      <b-tab title="Visa Rejections">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveStudentVisaRejections"
              >Save
            </b-button>
          </div>
          <b-card-body>
            <div v-for="(rejection, index) in activeStudentVisaRejections">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country">
                      <b-form-input
                        v-model="rejection.svr_country"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Type">
                      <b-form-input v-model="rejection.svr_type"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reason">
                      <b-form-input
                        v-model="rejection.svr_reason"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentVisaRejections(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentVisaRejection"
              >Add Another Visa Rejection</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>

      <!-- Student Family Details Section -->
      <b-tab title="Family Details">
        <b-card class="mb-3 history_card">
          <div class="save_button">
            <span></span>
            <b-button
              type="button"
              variant="success"
              @click="saveStudentFamilyDetails"
              >Save</b-button
            >
          </div>
          <b-card-body>
            <div v-for="(family, index) in activeStudentFamilyDetails">
              <div class="shadow p-2 mb-3 bg-white rounded">
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name *">
                      <b-form-input v-model="family.stf_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Type">
                      <v-select
                      v-model="family.sfd_type"
                      :options="[
                        { label: 'Parents', value: 'parents' },
                        { label: 'Siblings', value: 'siblings' },
                        { label: 'Sponsors', value: 'sponsors' },
                      ]"
                      
                      :reduce="(item) => item.value"
                      placeholder="Select Type"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Current Country">
                      <b-form-input
                        v-model="family.sfd_current_country"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date of Birth">
                      <b-form-input
                        type="date"
                        v-model="family.sfd_dob"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Age">
                      <b-form-input v-model="family.sfd_age"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Gender">
                      <b-form-input v-model="family.sfd_gender"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Marital Status">
                      <b-form-input
                        v-model="family.sfd_marital_status"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Email *">
                      <b-form-input v-model="family.sfd_email"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Contact Number *">
                      <b-form-input
                        v-model="family.sfd_contact_number"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employment Type">
                      <b-form-input
                        v-model="family.sfd_employment_type"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Residential Address *">
                      <b-form-input
                        v-model="family.sfd_company_name_address"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="ITR Available">
                      <v-select
                        v-model="family.sfd_itr_available"
                        :options="[
                          { label: 'Yes', value: 'Y' },
                          { label: 'No', value: 'N' },
                        ]"
                        
                        reduce="value"
                        placeholder="Select ITR Availability"
                        :reduce="(item) => item.value"
                      ></v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-col cols="12"  class="text-right">
                    <feather-icon
                      icon="TrashIcon"
                      size="25"
                      class="text-danger cursor-pointer"
                      @click="deleteStudentFamilyDetails(index)"
                    />
                  </b-col>
              </div>
            </div>
            <b-button
              type="button"
              style="float: right"
              variant="outline-primary"
              @click="addStudentFamilyDetail"
              >Add Another Family Detail</b-button
            >
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import {
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import StudentServices from "@/apiServices/StudentServices";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BContainer,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BTabs,
    BTab,
    vSelect,
  },
  data() {
    return {
      activeTab: 0,
      student_user_id:null,
      educationHistory: [
        {
          stq_name: "",
          stq_course: "",
          stq_college: "",
          stq_college_address: "",
          stq_contact: "",
          stq_awarding_board: "",
          stq_country_instituition: "",
          stq_date_from: "",
          stq_date_to: "",
          stq_grades: "",
          is_active: "Y",
        },
      ],
      studentGaps: [
        {
          stg_period_from: "",
          stg_period_to: "",
          stg_reason: "",
          stg_backlogs: "",
          is_active: "Y",
        },
      ],
      studentCertifications: [
        {
          stc_name: "",
          stc_overall: "",
          stc_speaking: "",
          stc_reading: "",
          stc_listening: "",
          stc_writing: "",
          stc_date: "",
          is_active: "Y",
        },
      ],
      studentTravelHistory: [
        {
          sth_country: "",
          sth_from: "",
          sth_to: "",
          sth_purpose: "",
          is_active: "Y",
        },
      ],
      studentVisaRejections: [
        {
          svr_country: "",
          svr_type: "",
          svr_reason: "",
          is_active: "Y",
        },
      ],
      studentFamilyDetails: [
        {
          sfd_id:null,
          stf_name: "",
          sfd_type: "",
          sfd_current_country: "",
          sfd_dob: "",
          sfd_age: "",
          sfd_gender: "",
          sfd_marital_status: "",
          sfd_email: "",
          sfd_contact_number: "",
          sfd_employment_type: "",
          sfd_company_name_address: "",
          sfd_itr_available: "",
          is_active: "Y",
        },
      ],
      studentWorkHistory: [
        {
          swh_name: "",
          swh_date_from: "",
          swh_date_to: "",
          swh_organisation: "",
          swh_org_address: "",
          swh_position: "",
          swh_employer_name: "",
          swh_employer_family_name: "",
          swh_employer_work_contact: "",
          swh_employer_contact: "",
          swh_employer_email: "",
          is_active: "Y",
        },
      ],
      educationOptions: [
        { label: "SSC", value: "class_10th" },
        { label: "HSC", value: "class_12th" },
        { label: "Diploma", value: "diploma" },
        { label: "Bachelors", value: "bachelor" },
        { label: "Masters", value: "masters" },
        { label: "Others", value: "additional_education" },
      ],
      workNameOptions: [
        { label: "Internship", value: "internship" },
        { label: "Fulltime", value: "fulltime" },
        { label: "Others", value: "others" },
      ],
    };

  },
  computed: {
    user_type() {
            if (store.getters["user/getUserDetails"]?.user_type) {
                return store.getters["user/getUserDetails"].user_type;
            } else {
                return null;
            }
        },
    activeEducationHistory() {
      return this.educationHistory.filter(item => item.is_active === 'Y');
    },
    activeStudentGaps() {
      return this.studentGaps.filter(item => item.is_active === 'Y');
    },
    activeStudentCertifications() {
      return this.studentCertifications.filter(item => item.is_active === 'Y');
    },
    activeStudentTravelHistory() {
      return this.studentTravelHistory.filter(item => item.is_active === 'Y');
    },
    activeStudentVisaRejections() {
      return this.studentVisaRejections.filter(item => item.is_active === 'Y');
    },
    activeStudentFamilyDetails() {
      return this.studentFamilyDetails.filter(item => item.is_active === 'Y');
    },
    activeStudentWorkHistory() {
      return this.studentWorkHistory.filter(item => item.is_active === 'Y');
    },
  }, 

  methods: {
    async saveEducationHistory() {
      try {
      let flag = true;
      this.educationHistory.forEach((item) => {
        if (
          item.stq_degree === "" ||
          item.stq_stream === "" ||
          item.stq_university === "" ||
          item.stq_date_from === "" ||
          item.stq_date_to === "" ||
          item.stq_grades === ""
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentQualifications({
            student_qualification: this.educationHistory,
          });
        } else {
          response = await CommonServices.saveStudentQualifications({
            student_qualification: this.educationHistory,
            student_user_id: this.student_user_id
          });
        }
        if (response.data.status) {
          this.activeTab = 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Qualifications saved successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveEducationHistory", error);
      }
    },
    async saveStudentGaps() {
      try {
        let flag = true;
      this.studentGaps.forEach((item) => {
        if (
          item.stg_period_from === "" ||
          item.stg_period_to === "" ||
          item.stg_reason === "" ||
          item.stg_backlogs === ""
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentGaps({
            student_gaps: this.studentGaps,
          });
        } else {
          response = await CommonServices.saveStudentGaps({
            student_gaps: this.studentGaps,
            student_user_id: this.student_user_id
          });
        }
        
        if (response.data.status) {
          this.activeTab = 2;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Gaps saved successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentGaps", error);
      }
    },
    async saveStudentCertifications() {
      try {
        let flag = true;
        this.studentCertifications.forEach((item) => {
        if (
          item.stc_name === "" ||
          item.stc_overall === "" ||
          item.stc_speaking === "" ||
          item.stc_reading === "" ||
          item.stc_listening === "" ||
          item.stc_writing === "" ||
          item.stc_date === "" ){
          flag = false;
          }
          if (!flag) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please fill all the fields",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            })
          }
      });
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentCertifications({
            student_certifications: this.studentCertifications,
          });
        } else {
          response = await CommonServices.saveStudentCertifications({
            student_certifications: this.studentCertifications,
            student_user_id: this.student_user_id
          });
        }
        if (response.data.status) {
          this.activeTab = 3;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Certifications saved successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentCertifications", error);
      }
    },
    async saveStudentWorkHistory() {
      let flag = true;
      this.studentWorkHistory.forEach((item) => {
        if (
          item.swh_name === "" ||
          item.swh_date_from === "" ||
          item.swh_date_to === "" ||
          item.swh_organisation === "" ||
          item.swh_org_address === "" ||
          item.swh_position === "" ||
          item.swh_employer_name === "" ||
          item.swh_employer_family_name === "" ||
          item.swh_employer_work_contact === "" ||
          item.swh_employer_contact === "" ||
          item.swh_employer_email === ""
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentWorkHistory({
            student_work_history: this.studentWorkHistory,
          });
        } else {
          response = await CommonServices.saveStudentWorkHistory({
            student_work_history: this.studentWorkHistory,
            student_user_id: this.student_user_id
          });
        }
        
        if (response.data.status) {
          this.activeTab = 4;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Work History Saved Successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentWorkHistory", error);
      }
    },
    async saveStudentTravelHistory() {
      let flag = true;
      this.studentTravelHistory.forEach((item) => {
        if (
          item.sth_country === "" ||
          item.sth_from === "" ||
          item.sth_to === "" ||
          item.sth_purpose === ""
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentTravelHistory({
            student_travel_history: this.studentTravelHistory,
          });
        } else {
          response = await CommonServices.saveStudentTravelHistory({
            student_travel_history: this.studentTravelHistory,
            student_user_id: this.student_user_id
          });
        }
        
        if (response.data.status) {
          this.activeTab = 5;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Travel History saved successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentTravelHistory", error);
      }
    },
    async saveStudentVisaRejections() {
      let flag = true;
      this.studentVisaRejections.forEach((item) => {
        if (
          item.svr_country === "" ||
          item.svr_type === "" ||
          item.svr_reason === ""
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentVisaRejections({
            student_visa_rejections: this.studentVisaRejections,
          });
        } else {
          response = await CommonServices.saveStudentVisaRejections({
            student_visa_rejections: this.studentVisaRejections,
            student_user_id: this.student_user_id
          });
        }
       
        if (response.data.status) {
          this.activeTab = 6;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Visa rejection saved Successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentVisaRejections", error);
      }
    },
    async saveStudentFamilyDetails() {
      let flag = true;
      this.studentFamilyDetails.forEach((item) => {
        if (
          item.stf_name === "" || 
          item.sfd_email === "" || 
          item.sfd_contact_number === "" || 
          item.sfd_company_name_address === "" 
        ) {
          flag = false;
        }
      });
      if (!flag) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill the required fields",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      try {
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.saveStudentFamilyDetails({
            student_family_details: this.studentFamilyDetails,
          });
        } else {
          response = await CommonServices.saveStudentFamilyDetails({
            student_family_details: this.studentFamilyDetails,
            student_user_id: this.student_user_id
          });
        }
       
        if (response.data.status) {
          this.activeTab = 7;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Family details saved successfully",
              icon: "CheckIcon",
              variant: "success",
            }
          })
        }
      } catch (error) {
        console.log("error in saveStudentFamilyDetails", error);
      }
    },

    addEducationHistory() {
      this.educationHistory.push({
        stq_name: "",
        stq_course: "",
        stq_college: "",
        stq_college_address: "",
        stq_contact: "",
        stq_awarding_board: "",
        stq_country_instituition: "",
        stq_date_from: "",
        stq_date_to: "",
        stq_grades: "",
        is_active: "Y",
      });
    },
    deleteEducationHistory(index) {
      // this.educationHistory.splice(index, 1);
      this.educationHistory[index].is_active = 'N';
    },
    addStudentGap() {
      this.studentGaps.push({
        stg_period_from: "",
        stg_period_to: "",
        stg_reason: "",
        stg_backlogs: "",
        is_active: "Y",
      });
    },
    deleteStudentGaps(index) {
      this.studentGaps[index].is_active = 'N';
    },
    addStudentCertification() {
      this.studentCertifications.push({
        stc_name: "",
        stc_overall: "",
        stc_speaking: "",
        stc_reading: "",
        stc_listening: "",
        stc_writing: "",
        stc_date: "",
        is_active: "Y",
      });
    },
    deleteStudentCertifications(index) {
      // this.studentCertifications.splice(index, 1);
      this.studentCertifications[index].is_active = 'N';
    },
    addStudentTravelHistory() {
      this.studentTravelHistory.push({
        sth_country: "",
        sth_from: "",
        sth_to: "",
        sth_purpose: "",
        is_active: "Y",
      });
    },
    deleteStudentTravelHistory(index) {
      // this.studentTravelHistory.splice(index, 1);
      this.studentTravelHistory[index].is_active = 'N';
    },
    addStudentVisaRejection() {
      this.studentVisaRejections.push({
        svr_country: "",
        svr_type: "",
        svr_reason: "",
        is_active: "Y",
      });
    },
    deleteStudentVisaRejections(index) {
      // this.studentVisaRejections.splice(index, 1);
      this.studentVisaRejections[index].is_active = 'N';
    },
    addStudentFamilyDetail() {
      this.studentFamilyDetails.push({
        stf_name: "",
        sfd_type: "",
        sfd_current_country: "",
        sfd_dob: "",
        sfd_age: "",
        sfd_gender: "",
        sfd_marital_status: "",
        sfd_email: "",
        sfd_contact_number: "",
        sfd_employment_type: "",
        sfd_company_name_address: "",
        sfd_itr_available: "",
        is_active: "Y",
      });
    },
    deleteStudentFamilyDetails(index) {
      // this.studentFamilyDetails.splice(index, 1);
      this.studentFamilyDetails[index].is_active = 'N';
    },
    addStudentWorkHistory() {
      this.studentWorkHistory.push({
        swh_name: "",
        swh_date_from: "",
        swh_date_to: "",
        swh_organisation: "",
        swh_org_address: "",
        swh_position: "",
        swh_employer_name: "",
        swh_employer_family_name: "",
        swh_employer_work_contact: "",
        swh_employer_contact: "",
        swh_employer_email: "",
        is_active: "Y",
      });
    },
    deleteStudentWorkHistory(index) {
      // this.studentWorkHistory.splice(index, 1);
      this.studentWorkHistory[index].is_active = 'N';
    },
    async getStudentAIS() {
      try {
        let response;
        if(this.user_type === 'student') {
          response = await StudentServices.getStudentAIS();
        } else {
          response = await CommonServices.getAIS(this.student_user_id);
        }
        if (response.data.status) {
          this.educationHistory = response.data.data.student_qualification
            .length
            ? response.data.data.student_qualification
            : this.educationHistory;
          this.studentGaps = response.data.data.student_gaps.length
            ? response.data.data.student_gaps
            : this.studentGaps;
          this.studentCertifications = response.data.data.student_certifications
            .length
            ? response.data.data.student_certifications
            : this.studentCertifications;
          this.studentWorkHistory = response.data.data.student_work_history
            .length
            ? response.data.data.student_work_history : this.studentWorkHistory;
          this.studentTravelHistory = response.data.data.student_travel_history
            .length
            ? response.data.data.student_travel_history
            : this.studentTravelHistory;
          this.studentVisaRejections = response.data.data
            .student_visa_rejections.length
            ? response.data.data.student_visa_rejections
            : this.studentVisaRejections;
          this.studentFamilyDetails = response.data.data.student_family_details
            .length
            ? response.data.data.student_family_details
            : this.studentFamilyDetails;



            if (this.educationHistory.length) {
              this.educationHistory.forEach((item) => {
                item.stq_date_from = item.stq_date_from
                  ? moment(item.stq_date_from).format("YYYY-MM-DD")
                  : item.stq_date_from;
                item.stq_date_to = item.stq_date_to
                  ? moment(item.stq_date_to).format("YYYY-MM-DD")
                  : item.stq_date_to;
              });
            }

            if (this.studentGaps.length) {
              this.studentGaps.forEach((item) => {
                item.stg_period_from = item.stg_period_from
                  ? moment(item.stg_period_from).format("YYYY-MM-DD")
                  : item.stg_period_from;
                item.stg_period_to = item.stg_period_to
                  ? moment(item.stg_period_to).format("YYYY-MM-DD")
                  : item.stg_period_to;
              });
            }

            if (this.studentWorkHistory.length) {
              this.studentWorkHistory.forEach((item) => {
                item.swh_date_from = item.swh_date_from
                  ? moment(item.swh_date_from).format("YYYY-MM-DD")
                  : item.swh_date_from;
                item.swh_date_to = item.swh_date_to
                  ? moment(item.swh_date_to).format("YYYY-MM-DD")
                  : item.swh_date_to;
              });
            }

            if (this.studentCertifications.length) {
              this.studentCertifications.forEach((item) => {
                item.stc_date = item.stc_date
                  ? moment(item.stc_date).format("YYYY-MM-DD")
                  : item.stc_date;
              });
            }

            if (this.studentTravelHistory.length) {
              this.studentTravelHistory.forEach((item) => {
                item.sth_from = item.sth_from
                  ? moment(item.sth_from).format("YYYY-MM-DD")
                  : item.sth_from;
                item.sth_to = item.sth_to
                  ? moment(item.sth_to).format("YYYY-MM-DD")
                  : item.sth_to;
              });
            }

            if (this.studentFamilyDetails.length) {
              this.studentFamilyDetails.forEach((item) => {
                item.stf_dob = item.stf_dob
                  ? moment(item.stf_dob).format("YYYY-MM-DD")
                  : item.stf_dob;
              });
            }
        }
      } catch (error) {
        console.log("error in getStudentAIS", error);
      }
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    this.student_user_id = student_user_id;
  },
  mounted() {
    if(this.user_type) {
      this.getStudentAIS();
    } else {
      setTimeout(() => {
        this.getStudentAIS();
      }, 500);
    }
  },
};
</script>


<style scoped>
.history_card{
position: relative;
}
.save_button{
  position: absolute;
  right: 0;
  top: -12px;
  right: 40px;
}
</style>